<template >
  <div>

    <topnav></topnav>
    <div class="page-wrap">
      <h1 class="txt160 serif light">
        {{ $t('Thank you for your trust') }}

      </h1>
      <p class="form-title">
        {{ $t('We will be in touch with you soon') }}
      </p>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
export default {
  components: {
    Topnav,
    Botfooter,
  },
  name: "Form"
};
</script>

<style>
.error {
  background-color: red;
  color: white;
  line-height: 1;
  padding: 8px;
  margin-top: 0;
  font-size: 16px;
  text-align: center;
}

.form-btn.img {
  color: #827C78;
  margin-top: 15px;
  font-size: 20px;
}
</style>